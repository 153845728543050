import { Crisp } from "crisp-sdk-web";

export default defineNuxtPlugin((_) => {
  const route = useRoute();
  const embedRoute = "/chat/embed";

  // We do not want to load Crisp on the embed route
  watch(() => route.path, () => {
    if (route.path.startsWith(embedRoute))
      return;

    const key = useRuntimeConfig().public.crispKey;
    Crisp.configure(key, {
      safeMode: true,
    });
  }, { immediate: true });

  return {
    provide: {
      crisp: Crisp,
    },
  };
});
