import validate from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-admin": () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/middleware/authAdmin.ts"),
  "auth-super-admin": () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/middleware/authSuperAdmin.ts"),
  "chatbot-limit": () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/middleware/chatbotLimit.ts"),
  "dc-auth": () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/middleware/dcAuth.ts"),
  auth: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}