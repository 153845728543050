import * as Sentry from "@sentry/vue";
import type { Router } from "#vue-router";

export default defineNuxtPlugin((nuxtApp) => {
  const { sentryDsn } = useRuntimeConfig().public;
  const isDev = import.meta.dev;

  // If no sentry DSN set, ignore and warn in the console
  if (!sentryDsn) {
    console.warn("Sentry DSN not set, skipping Sentry initialization");
    return;
  }

  Sentry.init({
    enabled: !isDev,
    app: nuxtApp.vueApp,
    dsn: sentryDsn,
    environment: isDev ? "dev" : "prod",
    integrations: [
      Sentry.browserTracingIntegration({
        // We do 'as router' because we are using typed router of Nuxt
        router: useRouter() as Router,
      }),
      Sentry.replayIntegration(),
    ],
    logErrors: isDev,
    attachProps: true,
    autoSessionTracking: true,
    trackComponents: true,
    timeout: 3000,
    hooks: ["activate", "create", "destroy", "mount", "update"],

    // Performance Monitoring
    tracesSampleRate: 0.2,

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    beforeSend(event, hint) {
      if (isDev) {
        // Log to console in development
        if (event.exception)
          console.error(hint.originalException);

        // Return null to drop the event in development
        return null;
      }

      return event;
    },
  });

  return {
    provide: {
      sentry: {
        setUser: Sentry.setUser,
        captureError: Sentry.captureException,
      },
    },
  };
});
