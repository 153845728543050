import { default as _91id_93fTEH7rlqvXMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/chat/[id].vue?macro=true";
import { default as _91id_93kSMABDfs3mMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/chat/embed/[id].vue?macro=true";
import { default as public8t1bqOAEAgMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/chat/public.vue?macro=true";
import { default as indexjSmlPLRkkUMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/index.vue?macro=true";
import { default as maintenance0ITNcScU28Meta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/maintenance.vue?macro=true";
import { default as sandboxzjxypT3gAyMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/sandbox.vue?macro=true";
import { default as signinqzrBecHvP0Meta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/signin.vue?macro=true";
import { default as signout9C82r3IntrMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/signout.vue?macro=true";
import { default as signupAcoivxD1teMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/signup.vue?macro=true";
import { default as indexXJQfmw1QEuMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/analytics/index.vue?macro=true";
import { default as sessionsbVttiORJR4Meta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue?macro=true";
import { default as analyticsvnfA93z4NrMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/analytics.vue?macro=true";
import { default as _91id_93tJvsXenfgLMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue?macro=true";
import { default as createOJQtGlxWkIMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/create.vue?macro=true";
import { default as _91id_93cj7F3T5052Meta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue?macro=true";
import { default as indexr2qELF4IyzMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/index.vue?macro=true";
import { default as documentsW1PVdEyg9KMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/documents.vue?macro=true";
import { default as driveAuthenticatedm9MgzNfMcwMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/driveAuthenticated.vue?macro=true";
import { default as indexcO3qwf6NAcMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/index.vue?macro=true";
import { default as postcheckoutMb3PkEAvjrMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/postcheckout.vue?macro=true";
import { default as resetPasswordn53NDO22XcMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/resetPassword.vue?macro=true";
import { default as sandboxFivCLHIxgzMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/sandbox.vue?macro=true";
import { default as indexYP819pV6ENMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/index.vue?macro=true";
import { default as integrationsPkY3pOBZgFMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/integrations.vue?macro=true";
import { default as legalokG2lYBqCFMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/legal.vue?macro=true";
import { default as organization6TNoYZyzLvMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/organization.vue?macro=true";
import { default as planSXZrLlcA2iMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/plan.vue?macro=true";
import { default as settingsc5WhrtJmcEMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings.vue?macro=true";
import { default as supportGFR0DkNlCIMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/support.vue?macro=true";
import { default as teamRkh62hNc3rMeta } from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/team.vue?macro=true";
export default [
  {
    name: _91id_93fTEH7rlqvXMeta?.name ?? "chat-id",
    path: _91id_93fTEH7rlqvXMeta?.path ?? "/chat/:id()",
    meta: _91id_93fTEH7rlqvXMeta || {},
    alias: _91id_93fTEH7rlqvXMeta?.alias || [],
    redirect: _91id_93fTEH7rlqvXMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kSMABDfs3mMeta?.name ?? "chat-embed-id",
    path: _91id_93kSMABDfs3mMeta?.path ?? "/chat/embed/:id()",
    meta: _91id_93kSMABDfs3mMeta || {},
    alias: _91id_93kSMABDfs3mMeta?.alias || [],
    redirect: _91id_93kSMABDfs3mMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: public8t1bqOAEAgMeta?.name ?? "chat-public",
    path: public8t1bqOAEAgMeta?.path ?? "/chat/public",
    meta: public8t1bqOAEAgMeta || {},
    alias: public8t1bqOAEAgMeta?.alias || [],
    redirect: public8t1bqOAEAgMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: indexjSmlPLRkkUMeta?.name ?? "index",
    path: indexjSmlPLRkkUMeta?.path ?? "/",
    meta: indexjSmlPLRkkUMeta || {},
    alias: indexjSmlPLRkkUMeta?.alias || [],
    redirect: indexjSmlPLRkkUMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenance0ITNcScU28Meta?.name ?? "maintenance",
    path: maintenance0ITNcScU28Meta?.path ?? "/maintenance",
    meta: maintenance0ITNcScU28Meta || {},
    alias: maintenance0ITNcScU28Meta?.alias || [],
    redirect: maintenance0ITNcScU28Meta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: sandboxzjxypT3gAyMeta?.name ?? "sandbox",
    path: sandboxzjxypT3gAyMeta?.path ?? "/sandbox",
    meta: sandboxzjxypT3gAyMeta || {},
    alias: sandboxzjxypT3gAyMeta?.alias || [],
    redirect: sandboxzjxypT3gAyMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: signinqzrBecHvP0Meta?.name ?? "signin",
    path: signinqzrBecHvP0Meta?.path ?? "/signin",
    meta: signinqzrBecHvP0Meta || {},
    alias: signinqzrBecHvP0Meta?.alias || [],
    redirect: signinqzrBecHvP0Meta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signout9C82r3IntrMeta?.name ?? "signout",
    path: signout9C82r3IntrMeta?.path ?? "/signout",
    meta: signout9C82r3IntrMeta || {},
    alias: signout9C82r3IntrMeta?.alias || [],
    redirect: signout9C82r3IntrMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: signupAcoivxD1teMeta?.name ?? "signup",
    path: signupAcoivxD1teMeta?.path ?? "/signup",
    meta: signupAcoivxD1teMeta || {},
    alias: signupAcoivxD1teMeta?.alias || [],
    redirect: signupAcoivxD1teMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: analyticsvnfA93z4NrMeta?.name ?? undefined,
    path: analyticsvnfA93z4NrMeta?.path ?? "/user/analytics",
    meta: analyticsvnfA93z4NrMeta || {},
    alias: analyticsvnfA93z4NrMeta?.alias || [],
    redirect: analyticsvnfA93z4NrMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: indexXJQfmw1QEuMeta?.name ?? "user-analytics",
    path: indexXJQfmw1QEuMeta?.path ?? "",
    meta: indexXJQfmw1QEuMeta || {},
    alias: indexXJQfmw1QEuMeta?.alias || [],
    redirect: indexXJQfmw1QEuMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: sessionsbVttiORJR4Meta?.name ?? "user-analytics-sessions",
    path: sessionsbVttiORJR4Meta?.path ?? "sessions",
    meta: sessionsbVttiORJR4Meta || {},
    alias: sessionsbVttiORJR4Meta?.alias || [],
    redirect: sessionsbVttiORJR4Meta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93tJvsXenfgLMeta?.name ?? "user-chatbots-chat-id",
    path: _91id_93tJvsXenfgLMeta?.path ?? "/user/chatbots/chat/:id()",
    meta: _91id_93tJvsXenfgLMeta || {},
    alias: _91id_93tJvsXenfgLMeta?.alias || [],
    redirect: _91id_93tJvsXenfgLMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: createOJQtGlxWkIMeta?.name ?? "user-chatbots-create",
    path: createOJQtGlxWkIMeta?.path ?? "/user/chatbots/create",
    meta: createOJQtGlxWkIMeta || {},
    alias: createOJQtGlxWkIMeta?.alias || [],
    redirect: createOJQtGlxWkIMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cj7F3T5052Meta?.name ?? "user-chatbots-edit-id",
    path: _91id_93cj7F3T5052Meta?.path ?? "/user/chatbots/edit/:id()",
    meta: _91id_93cj7F3T5052Meta || {},
    alias: _91id_93cj7F3T5052Meta?.alias || [],
    redirect: _91id_93cj7F3T5052Meta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexr2qELF4IyzMeta?.name ?? "user-chatbots",
    path: indexr2qELF4IyzMeta?.path ?? "/user/chatbots",
    meta: indexr2qELF4IyzMeta || {},
    alias: indexr2qELF4IyzMeta?.alias || [],
    redirect: indexr2qELF4IyzMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: documentsW1PVdEyg9KMeta?.name ?? "user-documents",
    path: documentsW1PVdEyg9KMeta?.path ?? "/user/documents",
    meta: documentsW1PVdEyg9KMeta || {},
    alias: documentsW1PVdEyg9KMeta?.alias || [],
    redirect: documentsW1PVdEyg9KMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: driveAuthenticatedm9MgzNfMcwMeta?.name ?? "user-driveAuthenticated",
    path: driveAuthenticatedm9MgzNfMcwMeta?.path ?? "/user/driveAuthenticated",
    meta: driveAuthenticatedm9MgzNfMcwMeta || {},
    alias: driveAuthenticatedm9MgzNfMcwMeta?.alias || [],
    redirect: driveAuthenticatedm9MgzNfMcwMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/driveAuthenticated.vue").then(m => m.default || m)
  },
  {
    name: indexcO3qwf6NAcMeta?.name ?? "user",
    path: indexcO3qwf6NAcMeta?.path ?? "/user",
    meta: indexcO3qwf6NAcMeta || {},
    alias: indexcO3qwf6NAcMeta?.alias || [],
    redirect: indexcO3qwf6NAcMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: postcheckoutMb3PkEAvjrMeta?.name ?? "user-postcheckout",
    path: postcheckoutMb3PkEAvjrMeta?.path ?? "/user/postcheckout",
    meta: postcheckoutMb3PkEAvjrMeta || {},
    alias: postcheckoutMb3PkEAvjrMeta?.alias || [],
    redirect: postcheckoutMb3PkEAvjrMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordn53NDO22XcMeta?.name ?? "user-resetPassword",
    path: resetPasswordn53NDO22XcMeta?.path ?? "/user/resetPassword",
    meta: resetPasswordn53NDO22XcMeta || {},
    alias: resetPasswordn53NDO22XcMeta?.alias || [],
    redirect: resetPasswordn53NDO22XcMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: sandboxFivCLHIxgzMeta?.name ?? "user-sandbox",
    path: sandboxFivCLHIxgzMeta?.path ?? "/user/sandbox",
    meta: sandboxFivCLHIxgzMeta || {},
    alias: sandboxFivCLHIxgzMeta?.alias || [],
    redirect: sandboxFivCLHIxgzMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/sandbox.vue").then(m => m.default || m)
  },
  {
    name: settingsc5WhrtJmcEMeta?.name ?? undefined,
    path: settingsc5WhrtJmcEMeta?.path ?? "/user/settings",
    meta: settingsc5WhrtJmcEMeta || {},
    alias: settingsc5WhrtJmcEMeta?.alias || [],
    redirect: settingsc5WhrtJmcEMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: indexYP819pV6ENMeta?.name ?? "user-settings",
    path: indexYP819pV6ENMeta?.path ?? "",
    meta: indexYP819pV6ENMeta || {},
    alias: indexYP819pV6ENMeta?.alias || [],
    redirect: indexYP819pV6ENMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsPkY3pOBZgFMeta?.name ?? "user-settings-integrations",
    path: integrationsPkY3pOBZgFMeta?.path ?? "integrations",
    meta: integrationsPkY3pOBZgFMeta || {},
    alias: integrationsPkY3pOBZgFMeta?.alias || [],
    redirect: integrationsPkY3pOBZgFMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: legalokG2lYBqCFMeta?.name ?? "user-settings-legal",
    path: legalokG2lYBqCFMeta?.path ?? "legal",
    meta: legalokG2lYBqCFMeta || {},
    alias: legalokG2lYBqCFMeta?.alias || [],
    redirect: legalokG2lYBqCFMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: organization6TNoYZyzLvMeta?.name ?? "user-settings-organization",
    path: organization6TNoYZyzLvMeta?.path ?? "organization",
    meta: organization6TNoYZyzLvMeta || {},
    alias: organization6TNoYZyzLvMeta?.alias || [],
    redirect: organization6TNoYZyzLvMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: planSXZrLlcA2iMeta?.name ?? "user-settings-plan",
    path: planSXZrLlcA2iMeta?.path ?? "plan",
    meta: planSXZrLlcA2iMeta || {},
    alias: planSXZrLlcA2iMeta?.alias || [],
    redirect: planSXZrLlcA2iMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: supportGFR0DkNlCIMeta?.name ?? "user-support",
    path: supportGFR0DkNlCIMeta?.path ?? "/user/support",
    meta: supportGFR0DkNlCIMeta || {},
    alias: supportGFR0DkNlCIMeta?.alias || [],
    redirect: supportGFR0DkNlCIMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: teamRkh62hNc3rMeta?.name ?? "user-team",
    path: teamRkh62hNc3rMeta?.path ?? "/user/team",
    meta: teamRkh62hNc3rMeta || {},
    alias: teamRkh62hNc3rMeta?.alias || [],
    redirect: teamRkh62hNc3rMeta?.redirect,
    component: () => import("/codebuild/output/src1563268556/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  }
]