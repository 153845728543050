import Plausible from "plausible-tracker";

export default defineNuxtPlugin(() => {
  // Setup
  const plausible = Plausible();
  let stopAutoPageViews: () => void | null;

  const isDev = process.dev;
  const route = useRoute();
  const includedRoutes = [
    "/user",
  ];

  // We watch the route to enable and disable auto page views based on the includedRoutes
  watch(() => route.path, () => {
    if (isDev)
      return;

    const isIncluded = includedRoutes.some(includedRoute => route.path.startsWith(includedRoute));

    if (isIncluded)
      stopAutoPageViews = plausible.enableAutoPageviews();
    else if (stopAutoPageViews)
      stopAutoPageViews();
  }, { immediate: true });
});
