<script setup lang="ts">
const { appUrl, maintenance } = useRuntimeConfig().public;

// Maintenance mode
if (useRoute().path !== "/maintenance" && maintenance.enabled)
  await navigateTo("/maintenance");

// SEO
useSeoMeta({
  title: "DocuChat",
  description: "Skip the Search: Get Instant, Accurate Answers from AI Chatbots Trained on Your Data",
  themeColor: "#0E9384",
  ogImage: `${appUrl}/images/welcome.jpg`,
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#2ED3B7" />
    <!-- <VitePwaManifest /> -->
    <UNotifications />

    <div>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>

    
  </div>
</template>

<style>
/* TODO: Bring back transitions once this resolves: https://github.com/vuejs/core/issues/8105 */
/* .slide-enter-active,
.slide-leave-active {
  transition: all 0.15s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translate(50px, 0);
} */
</style>
