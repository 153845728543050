import type { PostHog } from "posthog-js";
import posthog from "posthog-js";

export default defineNuxtPlugin({
  name: "posthog",
  parallel: true,
  setup() {
    const isDev = import.meta.dev;
    const { posthogHost, posthogPublicKey } = useRuntimeConfig().public;

    const posthogClient = posthog.init(posthogPublicKey, {
      api_host: posthogHost,
      autocapture: false,
      loaded: (posthog) => {
        if (isDev)
          posthog.debug();
      },
    }) as PostHog;

    const router = useRouter();
    router.afterEach((to) => {
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log("Ignoring pageview in dev mode.", to.fullPath);
        return;
      }

      nextTick(() => {
        posthogClient.capture("$pageview", {
          current_url: to.fullPath,
        });
      });
    });

    function captureEvent(eventName: string, properties?: Record<string, any>) {
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log("Ignoring event in dev mode.", eventName, properties);
        return;
      }

      posthogClient.capture(eventName, properties);
    }

    function identifyUser(id: string, email: string, organization: string) {
      posthogClient.identify(id, {
        email,
        organization,
      });
    }

    function reset() {
      posthogClient.reset();
    }

    return {
      provide: {
        posthog: {
          capture: captureEvent,
          identify: identifyUser,
          reset,
        },
      },
    };
  },
});
