<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps<{
  error: NuxtError;
}>();

useSeoMeta({
  title: props.error.statusCode === 404 ? "Page not found" : "An error occurred",
  description: props.error?.statusMessage ?? props.error.message,
});
</script>

<template>
  <UContainer>
    <UMain>
      <UPage class="flex min-h-screen items-center justify-center">
        <UPageError
          :status="error.statusCode"
          :name="error.statusMessage ?? 'An error occurred'"
          :message="error.message"
          :clear-button="{
            label: 'Go Back Home',
            to: '/',
            trailingIcon: 'i-carbon-arrow-right',
          }"
        />
      </UPage>
    </UMain>
  </UContainer>
</template>
