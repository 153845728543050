import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_LDumGYo2KH from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_1aypKNZ222 from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import analytics_client_mtqVw3YRXk from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/plugins/analytics.client.ts";
import autoAnimate_4HQGapz9xs from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/plugins/autoAnimate.ts";
import crisp_client_DCubnCdDP5 from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/plugins/crisp.client.ts";
import posthog_client_fUucxKWhZE from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/codebuild/output/src1563268556/src/DocuChatv2/web-app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod,
  analytics_client_mtqVw3YRXk,
  autoAnimate_4HQGapz9xs,
  crisp_client_DCubnCdDP5,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]