import { defineNuxtPlugin } from '#app/nuxt'
import { LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
